import * as React from "react"
import Layout from "../layouts/default"
import Seo from "../components/seo"
import * as style from "../sass/pages/topSecret.module.sass"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import { PortableText } from '@portabletext/react'





const TopSecretPage = ({ data }) => {
  const page = data.allSanityProjects.edges[0].node;
  const [_authorized, _setState] = React.useState(false);

  const toggleState = () => {
    _setState(!_authorized)

  }
  // Submit
  function submit(event) {
    event.stopPropagation();
    event.preventDefault();
    // Get Email and Password
    const password = document.getElementById('password').value;
    // Check if password is correct
    if (password === page.password) {
      // Toggle State
      toggleState();
      window.localStorage.setItem('top-secret-password', password);
    } else {
      alert('Wrong Password');
    }
  }


  if (typeof document !== 'undefined') {
    if (localStorage.getItem('top-secret-password') !== page.password) {
      return (
        <Layout>
          <Seo title="Top Secret" />
          <section className={style.mainContent}>
            <PortableText
              value={page._rawHeader}
              components={{
                marks: {
                  underline: ({ children }) => <u>{children}</u>,
                }
              }}
            />
            <form className={style.requestForm}>
              <input className="h6" id="password" type="password" name="password" placeholder="PASSWORD" required="{true}" autoComplete="current-password" />
              <button className="button" id="btnLogin" onClick={submit}>SUBMIT</button>
            </form>
            <a className="body1" href="mailto:Gurr@TheCoffeeStandard.com?subject=Requesting Access"><em>Request Access</em></a>
          </section>
          <section className={style.gallery}>
            {
              page.projectList.map((project, index) => (
                <div className={style.imageWrapper + ' ' + style.empty} key={project._key}>
                  <svg className={style.image} xmlns="http://www.w3.org/2000/svg" width="437.25" height="401.562" viewBox="0 0 437.25 401.562" preserveAspectRatio="none">
                    <g transform="translate(-58.5 536.937)">
                      <path d="M0-.122,436.254-400.447" transform="translate(59 -135.875)" fill="rgba(0,0,0,0)" stroke="#2b2e34" strokeWidth="1" />
                      <path d="M436.253-.118,0-400.443" transform="translate(59 -135.875)" fill="rgba(0,0,0,0)" stroke="#2b2e34" strokeWidth="1" />
                      <path d="M0,0H436.25V-400.562H0Z" transform="translate(59 -135.875)" fill="rgba(0,0,0,0)" stroke="#2b2e34" strokeWidth="1" />
                      <path d="M.5-.5H435.75V-400.062H.5Z" transform="translate(59 -135.875)" fill="rgba(0,0,0,0)" stroke="#2b2e34" strokeWidth="1" />
                      <path d="M0,0H436.25V-400.562H0Z" transform="translate(59 -135.875)" fill="rgba(0,0,0,0)" stroke="#2b2e34" strokeWidth="1" />
                    </g>
                  </svg>
                  <div className={style.metadata}>
                    <p className="caption captionBold">PROJECT {index + 1}</p>
                    <p className="caption">ACCESS DENIED</p>
                  </div>
                </div>
              ))
            }
          </section>
        </Layout>
      )
    } else {
      return (
        <Layout>
          <section className={style.mainContent}>
            <PortableText
              value={page._rawHeader}
              components={{
                marks: {
                  underline: ({ children }) => <u>{children}</u>,
                }
              }}
            />
            <p className={style.requestForm + " h6"}><em>ACCESS GRANTED!</em></p>
          </section>
          <section className={style.gallery}>
            {
              page.projectList.map((project, index) => (
                <Link to={`/project/` + project.project.slug.current} className={style.imageWrapper} key={project._key}>
                  <GatsbyImage className={style.image} image={project.image.asset.gatsbyImageData} alt={project.image.alt} />
                  <div className={project.image.luminosity === true ? style.metadata + ' ' + style.light : style.metadata}>
                    <p className="caption captionBold">{project.image.title}</p>
                    <p className="caption">{project.image.subtitle}</p>
                  </div>
                </Link>
              ))
            }
          </section>
        </Layout>
      )
    }
  } else {
    return null
  }
}

export default TopSecretPage


export const query = graphql`
{
  allSanityProjects {
    edges {
      node {
        _rawHeader
        password
        projectList {
          _key
          image {
            _key
            title
            subtitle
            alt
            luminosity
            asset {
              gatsbyImageData(
                  width: 1600
                placeholder: BLURRED
                )
            }
          }
          project {
            slug {
            current
          }
          }
        }
      }
    }
  }
}`